@import '~antd/dist/antd.less'; // Import Ant Design styles by less entry

@font-face {
  font-family: "Poppins Regular";
  src: url("../../public/fonts/Poppins-Regular.ttf") format("truetype");
  font-style: normal;
}

@font-face {
  font-family: "Poppins Medium";
  src: url("../../public/fonts/Poppins-Medium.ttf") format("truetype");
  font-style: normal;
}

@font-face {
  font-family: "Poppins SemiBold";
  src: url("../../public/fonts/Poppins-SemiBold.ttf") format("truetype");
  font-style: normal;
}

body {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  overflow-x: hidden;
  font-family: 'Poppins Regular', sans-serif;
}

#components-layout-demo-custom-trigger .trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

.logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
  background: #fff;
}

.trigger {
  margin: 0 auto;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.ant-menu {
  background-color: #F2F2F2 !important;
}

.ant-menu-item-icon {
  color: #606367 !important;
}

.ant-menu-sub.ant-menu-inline>.ant-menu-item,
.ant-menu-sub.ant-menu-inline>.ant-menu-submenu>.ant-menu-submenu-title {
  height: 100%;
  min-height: 42px;
  line-height: 20px;
}

.ant-menu-inline.ant-menu-root .ant-menu-item>.ant-menu-title-content,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title>.ant-menu-title-content {
  overflow: inherit;
  white-space: break-spaces;
  word-break: break-word;
}

.ant-radio-wrapper {
  align-items: center;
}

.ant-menu-title-content {
  color: #606367;
}

.ant-menu-item {
  border: none;
}

.ant-menu-item-only-child {
  .ant-menu-title-content {
  padding-left: 0.5em;
  }
}

.custom-scrollbar::-webkit-scrollbar {
  width: 0.1em;
}
.custom-scrollbar::-webkit-scrollbar-track {
  background: white;
}
.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: gray;
}
.custom-scrollbar::-moz-scrollbar {
  width: 0.1em;
}
.custom-scrollbar::-moz-scrollbar-track {
  background: white;
}
.custom-scrollbar::-moz-scrollbar-thumb {
  background-color: gray;
}

.ant-menu-inline .ant-menu-item::after {
  border-right: 3px solid #FF8727;
}

.ant-menu-item:hover {
  background-color: rgba(104, 204, 88, 0.5) !important;
}

.ant-menu-item-selected {
  background-color: #68CC58 !important;
  border: none !important;
 

  .ant-menu-title-content {
    color: #ffffff;

  }

  .ant-menu-item-icon {
    color: #ffffff !important;
  }
}

.ant-layout-sider-children {
  background-color: #F2F2F2 !important;
}

.ant-menu ant-menu-root {
  background-color: #F2F2F2 !important;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 70% !important;
  height: 100% !important;
}

.react-pdf__Page__textContent {
  display: none;
}

.ant-layout-content {
  background: #fff;
}

.ant-layout .site-layout {
  background: #fff;
}

.ant-card-bordered {
  border: 0.5px solid #000000;
}

.ant-collapse-header {
  flex-direction: row-reverse;
  justify-content: space-between;
}

.ant-select-selection-item {
  display: flex;
  align-items: center;
}

.card-bordered-shadow {
  background: #DEDEDE;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.ant-card-bordered {
  border: 1px solid #DEDEDE;
}

.ant-table-thead>tr>th {
  background: #dedede !important;
}

// Cores das linhas da tabela de propostas por status
.aprovada td:first-child {
  border-left: 5px solid #52C41A;
}

.pendente td:first-child {
  border-left: 5px solid #FF9100;
}

.analise td:first-child {
  border-left: 5px solid #1890FF;
}

.reprovada td:first-child {
  border-left: 5px solid #FF4D4F;
}

// Cores das linhas da tabela de contratos
.contrado-em-andamento td:first-child {
  border-left: 5px solid #8E8E8E;
}

.contrato-quitado td:first-child {
  border-left: 5px solid #87D068;
}

// Cores das linhas da tabela de parcelas de contratos
.contrado-em-andamento td:first-child {
  border-left: 5px solid #8E8E8E;
}

.contrato-quitado td:first-child {
  border-left: 5px solid #87D068;
}

.contrato-parcela-pendente td:first-child {
  border-left: 5px solid #36CBCB;
}

.contrato-parcela-pago td:first-child {
  border-left: 5px solid #3AA1FF;
}

.contrato-parcela-atrasada td:first-child {
  border-left: 5px solid #FFEA00;
}

.contrato-parcela-atrasada-2 td:first-child {
  border-left: 5px solid #FF8727;
}

.contrato-parcela-cobranca td:first-child {
  border-left: 5px solid #FF0000;
}

.icones-default {
  font-size: 22px !important
}

.usuario-dropdown {
  border: 1px solid #FF8727;
  border-radius: 5px;
  background-color: #F2F2F2 !important;
}

.ant-alert-info {
  background-color: #e6f7ff;
  border: 1px solid #91d5ff;

  .ant-alert-icon {
    color: #1890ff;
  }
}

.upload-display-block .ant-upload-select {
  display: block !important;
}

.ant-spin-nested-loading {
  width: 100%;
}
@primary-color: #2D9A47;@secondary-color: #2D9A47;